:root {
  //primary
  --primary: #35753A;
  --secondary: #656E76;
  --danger: #c0304a;
  --select: #E8EBEC;
  --main: white;

  //button
  --button-primary: var(--primary);
  --button-color: white;
  --button-primary-hover: #245029;
  --button-danger: #c0304a;

  --button-secondary: white;
  --button-secondary-color: var(--primary);

  --button-select: #E8EBEC;
  --button-select-color: #656E76;

  //text
  --text-primary: #535B63;
  --text-danger: #c0304a;
  --text-title: var(--primary);
  --text-accent: var(--primary);

  //block
  --block-background: white;
  --block-box-shadow: 0 4px 28px rgba(0, 0, 0, 0.1);

  //marker
  --marker-primary: var(--primary);
  --marker-color: white;
  --marker-primary-hover: var(--primary);

  --marker-secondary: white;
  --marker-secondary-color:var(--primary);
  --marker-secondary-border: var(--primary);

  //input
  --input-color: var(--primary);
  --input-background: #ECEFF0;
  --input-background-hover: #d1d4d5;

  --input-radio-background: white;
  --input-radio-not-checked-border: #ECEFF0;
  --input-radio-border:var(--primary);

  --input-border-success: var(--primary);
  --input-border-warning: #c0304a;

  --input-outline: #656E76;

  //loader
  --loader-color: var(--primary);

  //footer
  --footer-primary: var(--primary);
  --footer-secondary: #666E75;
  --footer-color: white;

  //bonus
  --bonus-quantiy: #c0304a;
  --bonus-icon-close: white;
  --bonus-icon-background: rgba(0, 0, 0, 0.2);

  //google button
  --google-button-background: white;
  --google-button-color: #444;
  --google-button-border: #888;

  //dark theme
  --dark-theme-primary-background: #0f0f0f;
  --dark-theme-secondary-background: #171717;
  --dark-theme-input-background: #222;
  --dark-theme-hover: #555;
  --dark-theme-sprimary-color: #B4B6B7;
  --dark-theme-text-danger: #B9373D;
  --dark-theme-text-accent: #35753A;
}
