/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '../node_modules/bootstrap/scss/bootstrap';
@import "assets/variables";
//@import '~ngx-sharebuttons/themes/material/material-dark-theme';
//@import '~ngx-sharebuttons/themes/modern/modern-light-theme';
//@import '~ngx-sharebuttons/themes/circles/circles-dark-theme';

html, body {
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);

}

html {
  position: relative;
  min-height: 100%;
}

.dark-theme {
  background-color: var(--dark-theme-primary-background);
  color: var(--dark-theme-sprimary-color);


  span {
    color: var(--dark-theme-sprimary-color) !important;
  }

  .sticky-menu {
    color: var(--dark-theme-sprimary-color) !important;

    &__shadow {
      background-color: var(--dark-theme-secondary-background) !important;
    }
  }

  .product-card {
    background-color: var(--dark-theme-secondary-background) !important;
  }

  .product-name {
    color: var(--dark-theme-sprimary-color) !important;
  }

  .bonus {
    &__quantity {
      color: var(--dark-theme-text-danger)!important;
    }
  }

  .sale-price {

    &__sum {
      color: var(--dark-theme-sprimary-color) !important;
    }
  }

  .modal-content {
    background-color: var(--dark-theme-secondary-background);
  }

  .weight-container {
    background: var(--dark-theme-secondary-background) !important;
  }

  .search, .sorting-button {
    background-color: var(--dark-theme-input-background) !important;
    color: var(--dark-theme-sprimary-color) !important;

    &:hover {
      background-color: var(--dark-theme-hover) !important;
    }
  }
  .sorting-selected{
    background-color: var(--dark-theme-hover) !important;
  }

  .drop-menu-filter {
    background: var(--dark-theme-input-background) !important;
  }

  .text-color {
    color: var(--dark-theme-sprimary-color)
  }

  .grey-font {
    color: var(--dark-theme-sprimary-color)
  }

  .menu {
    color: var(--dark-theme-sprimary-color) !important;
  }

  .franchise {
    color: var(--dark-theme-sprimary-color) !important;
  }

  .drop-menu {
    background: var(--dark-theme-primary-background) !important;
  }

  .drop-menu-button {
    color: var(--dark-theme-sprimary-color) !important;
    &:hover {
      color: var(--main) !important;
      div {
        span {
          color: var(--main) !important;
        }
      }
    }
  }

  .enable-text {
    span {
      color: var(--main) !important;
    }
  }

  .green-button {
    color: var(--main) !important;
  }

  .grey-button {
    background: var(--dark-theme-secondary-background) !important;
  }

  .close-btn-all {
    background: var(--dark-theme-secondary-background) !important;
  }

  .checkmark {
    background: var(--dark-theme-secondary-background) !important;
  }

  .product-card {
    .price {
      color: var(--dark-theme-sprimary-color) !important;
    }
  }

  input:checked ~ .checkmark {
    background-color: var(--marker-primary) !important;
  }

  #customBtn {
    background: var(--dark-theme-secondary-background) !important;
    color: var(--dark-theme-sprimary-color) !important;
  }

  .ordering {
    background: var(--dark-theme-secondary-background) !important;
    .small-red-font {
      color: var(--dark-theme-text-danger) !important;
    }
    .error-hint {
      color: var(--dark-theme-text-danger) !important;
    }
    .free-product {
      &__per-piece {
        color: var(--dark-theme-text-accent) !important;
        span {
          color: var(--dark-theme-text-accent) !important;
        }
      }
    }
    .free-product-block {
      .quantity-price__free {
        color: var(--main) !important;
      }
    }
    .white-font {
      color: var(--main) !important;
    }
    .send-order__text {
      color: var(--main) !important;
    }
    .green-font-order {
      color: var(--main) !important;
      span {
        color: var(--main) !important
      }
    }
    .search, .search-textarea, .drop-menu-button {
      color: var(--main) !important
    }

    .small-gray-font {
      color: var(--dark-theme-sprimary-color);
    }
  }

  .cart {
    background: var(--dark-theme-secondary-background) !important;
  }

  .navbar-mobile {
    background: var(--dark-theme-secondary-background) !important;
  }

  .navbar {
    .grey-button {
      span {
        color: var(--dark-theme-text-accent) !important;
      }
    }
  }

  .cart {
    &__summary {
      .grey-font, .green-font {
        color: var(--dark-theme-text-accent) !important;
      }
      .red-font {
        color: var(--dark-theme-text-danger) !important;
      }
    }
  }

  .pieces-container {
    .pieces {
      color: var(--main) !important;
    }
  }

  .action-buttons {
    .green-button {
      &__text {
        color: var(--main) !important;
      }
    }
  }

  .profile-block {
    .tab-active {
      .tab__title {
        color: var(--main) !important;
      }
    }
    .bonus-qty {
      &__icon-wrapper {
        background: var(--dark-theme-text-accent) !important;;
      }
    }
    .address {
      .green {
        color: var(--dark-theme-text-accent) !important;
      }
    }
  }

  .header-block {
    .accent-text {
      color: var(--dark-theme-text-accent) !important;
    }
  }

  .filter-container {
    .search {
      color: var(--dark-theme-text-accent) !important;
    }
  }

  .card-item {
    background: var(--dark-theme-primary-background) !important;
  }

  .set-info {
    background: var(--dark-theme-secondary-background) !important;

    &__size {
      background-color:  var(--dark-theme-secondary-background) !important;
    }
  }

  .marking {
    color: var(--dark-theme-sprimary-color) !important;
  }
  .disclaimer {
    background-color: var(--dark-theme-secondary-background) !important;
  }

  .referal__create-button {
    span {
      color: var(--main) !important;
    }
  }

  .green-font {
    color: var(--marker-secondary-color) !important;
  }

  .red-font {
    color: var(--text-danger) !important;
  }

  .bonus {
    background: var(--dark-theme-secondary-background) !important;
  }

  .search-textarea {
    background: var(--dark-theme-input-background) !important;
    color: var(--dark-theme-sprimary-color) !important;
  }

  .green-font-order {
    color: var(--dark-theme-sprimary-color) !important;

  }

  .constructor {
    &__variants {
      background-color: var(--dark-theme-primary-background) !important;

      .information {
        p {
          color: var(--dark-theme-sprimary-color) !important;
        }

      }
    }
  }

  .footer {
    a, span {
      color: var(--main) !important;
    }
  }

  .save-btn {
    background-color: var(--dark-theme-primary-background) !important;
  }

  .circle-left {
    background-color: var(--dark-theme-input-background) !important;
  }

  .circle-right {
    background-color: var(--dark-theme-input-background) !important;
  }

  #dark-accent-text{
    color: var(--text-accent) !important;
  }

  #dark-primary-text{
    color: var(--dark-theme-sprimary-color) !important;
  }

  .bcg-dark{
    background-color: var(--dark-theme-secondary-background) !important;
  }
}

.grey-font {
  color: #656E76;
  transition: all .3s ease;

}

.grey-font-hover {
  &:hover {
    color: #245029 !important;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.lds-spinner {
  color: black;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #858585;
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

.custom-modal {
  max-height: 95vh;
  overflow: hidden;

  .modal-dialog {
    overflow: hidden;
  }

  .scroll {
    max-height: 80vh;
    overflow-y: auto;
  }

  .scroll-2 {
    max-height: 60vh;
    overflow-y: auto;
  }
}

.custom-modal-sm {
  margin-top: 200px;
}

@media screen and (max-width: 768px) {

  .custom-modal {
    max-height: 99vh;

    .scroll-2 {
      min-height: 50%;
      max-height: 33vh;
    }

    .mobile-scroll {
      min-height: auto;
    }
  }
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.accent-background{
  background-color: var(--primary) !important;
}

.accent-text{
  color:var(--primary) !important;
}

.img-wrapper-width {
  width: fit-content;
}

.error-report-trigger {
  color: var(--text-danger);
  font-weight: 700;
  font-size: 14px;
  background-color: transparent;
  border: none;
}

#sentry-feedback {
  --accent-background:var(--text-danger);
  --button-color:var(--input-outline);
  --z-index:100000000;
}